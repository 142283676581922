import { combineReducers } from 'redux'
import appReducer from './App'
import authReducer from './Auth'
import messageReducer from './Message'
import confirmReducer from './Confirm'
import printReducer from './Print'
import formReducer from './Form'
import listPageReducer from './ListPage'
import listPageAjaxReducer from './ListPageAjax'
import blockReducer from './Block'
import inlineFormReducer from './InlineForm'
import updateReducer from './Update'
import sidebarReducer from './Sidebar'
import statusReducer from './Status'
import dashboardReducer from './Dashboard'
import agendaReducer from './Agenda'
import paginationConfigReducer from './PaginationConfig'

const reducers = combineReducers({
  appReducer,
  authReducer,
  messageReducer,
  confirmReducer,
  printReducer,
  formReducer,
  listPageReducer,
  listPageAjaxReducer,
  blockReducer,
  inlineFormReducer,
  updateReducer,
  sidebarReducer,
  statusReducer,
  dashboardReducer,
  agendaReducer,
  paginationConfigReducer
})

export default reducers
