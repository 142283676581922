const { REACT_APP_NODE_ENV } = process.env

window.appConfig = {
  timeouts: {},
  appPrefix: '/app',
  settingPrefix: '/setting',
  appEnv: REACT_APP_NODE_ENV,
  apiUrl: REACT_APP_NODE_ENV === 'cloud' ? '' : 'http://localhost:3001',
  dateFormat: 'DD/MM/YYYY',
  dateFormatPattern: new RegExp('[0-9]{2}/[0-9]{2}/[0-9]{4}'),
  actions: {
    dashboard: {
      dashboardGet: {
        path: '/api/:db/dashboard'
      }
    },
    chatbot: {
      chatbotPost: {
        path: '/api/chatbot'
      }
    },
    updates: {
      syncAll: {
        path: '/api/sync/sync-all'
      },
      updateAll: {
        path: '/api/sync/update-all',
        timeout: 120000
      },
      checkGet: {
        path: '/api/sync/update-status',
        timeout: 120000
      },
      updateProgress: {
        path: '/api/sync/update-percentage',
        timeout: 2000
      },
      getNotifications: {
        path: '/api/sync/get-notifications',
        timeout: 120000
      },
      uploadDriveZips: {
        path: '/api/sync/oc-upload',
        timeout: 180000
      }
    },
    calendar: {
      get: {
        path: '/api/:db/calendar'
      },
      filters: {
        path: '/api/:db/calendar/filters'
      },
      save: {
        path: '/api/:db/calendar'
      },
      delete: {
        path: '/api/:db/calendar/delete'
      },
      workerAgendaSavePost: {
        path: '/api/:db/calendar/save-agenda-worker'
      },
      workerAgendaDeletePost: {
        path: '/api/:db/calendar/delete-agenda-worker'
      },
      search: {
        path: '/api/:db/calendar/search'
      },
    },
    log: {
      loadData: {
        path: '/api/:db/log/edit'
      }
    },
    annex3b: {
      annex3bGet: {
        path: '/api/:db/3b/edit'
      },
      annex3bSavePost: {
        path: '/api/:db/3b/save'
      }
    },
    annex3bFinalize: {
      annex3bFinalizeGet: {
        path: '/api/:db/3b/finalize'
      }
    },
    annex3bDownload: {
      annex3bDownloadGet: {
        path: '/api/:db/3b/download'
      }
    },
    importWorkers: {
      importWorkersGet: {
        path: '/api/:db/import-workers'
      },
      importWorkersSavePost: {
        path: '/api/:db/import-workers'
      }
    },
    auth: {
      loginPost: {
        path: '/api/auth/login'
      },
      logoutPost: {
        path: '/api/auth/logout'
      },
      localDbsProgressGet: {
        path: '/api/auth/local-dbs-progress'
      },
      passwordRecovery: {
        path: '/api/recovery'
      },
      checkOtp: {
        path: '/api/auth/check-otp'
      },
      resetOtp: {
        path: '/api/auth/reset-otp'
      }
    },
    app: {
      fillSidebar: {
        path: '/api/:db/user/sidebar'
      },
      fillDbList: {
        path: '/api/db'
      },
      appConfig: {
        path: '/api/:db/user/app-config'
      },
      checkSoftwareUpdate: {
        path: '/api/info/check-sw-update',
        timeout: 2000
      },
      installSoftwareUpdate: {
        path: '/api/info/install-sw-update'
      }
    },
    address: {
      loadLocations: {
        path: '/api/:db/helper/locations'
      }
    },
    block: {
      blockGet: {
        path: '/api/:db/questionnaire/edit'
      },
      blockPost: {
        path: '/api/:db/questionnaire/save'
      },
      blockDeletePost: {
        path: '/api/:db/questionnaire/delete'
      }
    },
    task: {
      taskPageGet: {
        path: '/api/:db/task/page'
      },
      tasksGet: {
        path: '/api/:db/task'
      },
      taskGet: {
        path: '/api/:db/task/edit'
      },
      taskSavePost: {
        path: '/api/:db/task/save'
      },
      taskDeletePost: {
        path: '/api/:db/task/delete'
      },
      taskProtocol: {
        path: '/api/:db/task/risks-protocol'
      },
      tasksTanks: {
        path: '/api/:db/task/risk-tanks'
      }
    },
    inspectionReport: {
      inspectionReportPageGet: {
        path: '/api/:db/inspection-report/page'
      },
      inspectionReportsGet: {
        path: '/api/:db/inspection-report'
      },
      inspectionReportGet: {
        path: '/api/:db/inspection-report/edit'
      },
      inspectionReportSavePost: {
        path: '/api/:db/inspection-report/edit'
      },
      inspectionReportDeletePost: {
        path: '/api/:db/inspection-report/delete'
      }
    },
    safetyInspectionReport: {
      safetyInspectionReportPageGet: {
        path: '/api/:db/safety-inspection-report/page'
      },
      safetyInspectionReportsGet: {
        path: '/api/:db/safety-inspection-report'
      },
      safetyInspectionReportGet: {
        path: '/api/:db/safety-inspection-report/edit'
      },
      safetyInspectionReportSavePost: {
        path: '/api/:db/safety-inspection-report/edit'
      },
      safetyInspectionReportDeletePost: {
        path: '/api/:db/safety-inspection-report/delete'
      }
    },
    healthReport: {
      healthReportPageGet: {
        path: '/api/:db/health-report/page'
      },
      healthReportsGet: {
        path: '/api/:db/health-report'
      },
      healthReportGet: {
        path: '/api/:db/health-report/edit'
      },
      healthReportSavePost: {
        path: '/api/:db/health-report/edit'
      },
      healthReportDeletePost: {
        path: '/api/:db/health-report/delete'
      }
    },
    exposeRegister: {
      // exposeRegisterPageGet: {
      //   path: '/api/:db/expose-register/page'
      // },
      exposeRegistersGet: {
        path: '/api/:db/expose-register'
      },
      exposeRegisterGet: {
        path: '/api/:db/expose-register/edit'
      },
      exposeRegisterSavePost: {
        path: '/api/:db/expose-register/edit'
      },
      exposeRegisterDeletePost: {
        path: '/api/:db/expose-register/delete'
      }
    },
    dueAct: {
      dueActPageGet: {
        path: '/api/:db/due-act/page'
      },
      dueActsGet: {
        path: '/api/:db/due-act'
      },
      dueActGet: {
        path: '/api/:db/due-act/edit'
      },
      dueActSavePost: {
        path: '/api/:db/due-act/edit'
      },
      dueActDeletePost: {
        path: '/api/:db/due-act/delete'
      }
    },
    agenda: {
      agendaGet: {
        path: '/api/:db/agenda/edit'
      },
      agendaSavePost: {
        path: '/api/:db/agenda/save'
      },
      agendaDeletePost: {
        path: '/api/:db/agenda/delete'
      }
    },
    rsppRelation: {
      rsppRelationGet: {
        path: '/api/:db/rspp-relation/edit'
      },
      rsppRelationSavePost: {
        path: '/api/:db/rspp-relation/edit'
      },
      rsppRelationDeletePost: {
        path: '/api/:db/rspp-relation/delete'
      }
    },
    company: {
      companyGetFiltered: {
        path: '/api/:db/company/filtered'
      },
      companyPageGet: {
        path: '/api/:db/company/page'
      },
      companySavePost: {
        path: '/api/:db/company/save'
      },
      companiesGet: {
        path: '/api/:db/company'
      },
      companyGet: {
        path: '/api/:db/company/edit'
      },
      companyDeletePost: {
        path: '/api/:db/company/delete'
      }
    },
    exportCompany: {
      exportCompanyGet: {
        path: '/api/:db/company/export'
      },
      exportCompanySavePost: {
        path: '/api/:db/company/export/save'
      }
    },
    joinCompany: {
      joinCompanyGet: {
        path: '/api/:db/company/join'
      },
      joinCompanySavePost: {
        path: '/api/:db/company/join/save'
      }
    },
    worker: {
      workerPageGet: {
        path: '/api/:db/worker/page'
      },
      workerSavePost: {
        path: '/api/:db/worker/save'
      },
      workersGet: {
        path: '/api/:db/worker'
      },
      workerGet: {
        path: '/api/:db/worker/edit'
      },
      workerDeletePost: {
        path: '/api/:db/worker/delete'
      },
      workerProtocol: {
        path: '/api/:db/worker/risks-protocol'
      },
    },
    companyWorker: {
      companyWorkerGetFiltered: {
        path: '/api/:db/worker/filtered'
      },
      companyWorkerPageGet: {
        path: '/api/:db/worker/page'
      },
      companyWorkersGet: {
        path: '/api/:db/worker'
      },
      companyWorkerDeletePost: {
        path: '/api/:db/worker/delete'
      },
    },
    print: {
      printStatus: {
        path: '/api/:db/print/status',
        timeout: 1000
      },
      printCancel: {
        path: '/api/:db/print/cancel',
      }
    },
    workerPrint: {
      workerPrintPageGet: {
        path: '/api/:db/worker-print/page'
      }
    },
    user: {
      userPageGet: {
        path: '/api/:db/user/page'
      },
      userSavePost: {
        path: '/api/:db/user/save'
      },
      usersGet: {
        path: '/api/:db/user'
      },
      userGet: {
        path: '/api/:db/user/edit'
      },
      userDeletePost: {
        path: '/api/:db/user/delete'
      },
      updatePassword: {
        path: '/api/:db/user/update-password'
      },
      hasLocalDbs: {
        path: '/api/has-local-dbs'
      }
    },
    userSetting: {
      userSettingPageGet: {
        path: '/api/:db/admin-user/page'
      },
      userSettingSavePost: {
        path: '/api/:db/admin-user/save'
      },
      userSettingsGet: {
        path: '/api/:db/admin-user'
      },
      userSettingGet: {
        path: '/api/:db/admin-user/edit'
      },
      userSettingDeletePost: {
        path: '/api/:db/admin-user/delete'
      },
      updatePassword: {
        path: '/api/:db/admin-user/update-password'
      },
      hasLocalDbs: {
        path: '/api/has-local-dbs'
      }
    },
    userLog: {
      userLogPageGet: {
        path: '/api/:db/admin-user/logs/page'
      },
      userLogsGet: {
        path: '/api/:db/admin-user/logs'
      },
    },
    system: {
      systemSavePost: {
        path: '/api/:db/system/save'
      },
      systemGet: {
        path: '/api/:db/system'
      }
    },
    userProfile: {
      userProfileSavePost: {
        path: '/api/:db/user/profile/save'
      },
      userProfileGet: {
        path: '/api/:db/user/profile'
      }
    },
    role: {
      rolePageGet: {
        path: '/api/role/page'
      },
      roleSavePost: {
        path: '/api/role/save'
      },
      rolesGet: {
        path: '/api/role'
      },
      roleGet: {
        path: '/api/role/edit'
      },
      roleDeletePost: {
        path: '/api/role/delete'
      }
    },
    addressType: {
      addressTypePageGet: {
        path: '/api/:db/address-type/page'
      },
      addressTypeSavePost: {
        path: '/api/:db/address-type/save'
      },
      addressTypesGet: {
        path: '/api/:db/address-type'
      },
      addressTypeGet: {
        path: '/api/:db/address-type/edit'
      },
      addressTypeDeletePost: {
        path: '/api/:db/address-type/delete'
      }
    },
    prescriptionType: {
      prescriptionTypePageGet: {
        path: '/api/:db/prescription/page'
      },
      prescriptionTypeSavePost: {
        path: '/api/:db/prescription/save'
      },
      prescriptionTypesGet: {
        path: '/api/:db/prescription'
      },
      prescriptionTypeGet: {
        path: '/api/:db/prescription/edit'
      },
      prescriptionTypeDeletePost: {
        path: '/api/:db/prescription/delete'
      }
    },
    limitationType: {
      limitationTypePageGet: {
        path: '/api/:db/limitation/page'
      },
      limitationTypeSavePost: {
        path: '/api/:db/limitation/save'
      },
      limitationTypesGet: {
        path: '/api/:db/limitation'
      },
      limitationTypeGet: {
        path: '/api/:db/limitation/edit'
      },
      limitationTypeDeletePost: {
        path: '/api/:db/limitation/delete'
      }
    },
    workerTitle: {
      workerTitlePageGet: {
        path: '/api/:db/worker-title/page'
      },
      workerTitleSavePost: {
        path: '/api/:db/worker-title/save'
      },
      workerTitlesGet: {
        path: '/api/:db/worker-title'
      },
      workerTitleGet: {
        path: '/api/:db/worker-title/edit'
      },
      workerTitleDeletePost: {
        path: '/api/:db/worker-title/delete'
      }
    },
    audioDiagnosis: {
      audioDiagnosisPageGet: {
        path: '/api/:db/audio-diagnosis/page'
      },
      audioDiagnosisSavePost: {
        path: '/api/:db/audio-diagnosis/save'
      },
      audioDiagnosissGet: {
        path: '/api/:db/audio-diagnosis'
      },
      audioDiagnosisGet: {
        path: '/api/:db/audio-diagnosis/edit'
      },
      audioDiagnosisDeletePost: {
        path: '/api/:db/audio-diagnosis/delete'
      }
    },
    contactType: {
      contactTypePageGet: {
        path: '/api/:db/contact-type/page'
      },
      contactTypeSavePost: {
        path: '/api/:db/contact-type/save'
      },
      contactTypesGet: {
        path: '/api/:db/contact-type'
      },
      contactTypeGet: {
        path: '/api/:db/contact-type/edit'
      },
      contactTypeDeletePost: {
        path: '/api/:db/contact-type/delete'
      }
    },
    tasksTank: {
      tasksTankPageGet: {
        path: '/api/:db/tasks-tank/page'
      },
      tasksTankSavePost: {
        path: '/api/:db/tasks-tank/save'
      },
      tasksTanksGet: {
        path: '/api/:db/tasks-tank'
      },
      tasksTankGet: {
        path: '/api/:db/tasks-tank/edit'
      },
      tasksTankProtocol: {
        path: '/api/:db/tasks-tank/risks-protocol'
      },
      tasksTankDeletePost: {
        path: '/api/:db/tasks-tank/delete'
      }
    },
    riskFactor: {
      riskFactorPageGet: {
        path: '/api/:db/risk-factor/page'
      },
      riskFactorSavePost: {
        path: '/api/:db/risk-factor/save'
      },
      riskFactorsGet: {
        path: '/api/:db/risk-factor'
      },
      riskFactorGet: {
        path: '/api/:db/risk-factor/edit'
      },
      riskFactorPreview: {
        path: '/api/:db/risk-factor/risk-preview'
      },
      riskFactorProtocol: {
        path: '/api/:db/risk-factor/risk-protocols'
      },
      riskFactorDeletePost: {
        path: '/api/:db/risk-factor/delete'
      }
    },
    assessment: {
      assessmentPageGet: {
        path: '/api/:db/assessment/page'
      },
      assessmentSavePost: {
        path: '/api/:db/assessment/save'
      },
      assessmentsGet: {
        path: '/api/:db/assessment'
      },
      assessmentGet: {
        path: '/api/:db/assessment/edit'
      },
      assessmentDeletePost: {
        path: '/api/:db/assessment/delete'
      }
    },
    resultType: {
      resultTypePageGet: {
        path: '/api/:db/result-type/page'
      },
      resultTypeSavePost: {
        path: '/api/:db/result-type/save'
      },
      resultTypesGet: {
        path: '/api/:db/result-type'
      },
      resultTypeGet: {
        path: '/api/:db/result-type/edit'
      },
      resultTypeDeletePost: {
        path: '/api/:db/result-type/delete'
      }
    },
    group3b: {
      group3bPageGet: {
        path: '/api/:db/group3b/page'
      },
      group3bSavePost: {
        path: '/api/:db/group3b/save'
      },
      group3bsGet: {
        path: '/api/:db/group3b'
      },
      group3bGet: {
        path: '/api/:db/group3b/edit'
      },
      group3bDeletePost: {
        path: '/api/:db/group3b/delete'
      }
    },
    question: {
      questionPageGet: {
        path: '/api/:db/question/page'
      },
      questionSavePost: {
        path: '/api/:db/question/save'
      },
      questionsGet: {
        path: '/api/:db/question'
      },
      questionGet: {
        path: '/api/:db/question/edit'
      },
      questionDeletePost: {
        path: '/api/:db/question/delete'
      }
    },
    answer: {
      answerPageGet: {
        path: '/api/:db/answer/page'
      },
      answerSavePost: {
        path: '/api/:db/answer/save'
      },
      answersGet: {
        path: '/api/:db/answer'
      },
      answerGet: {
        path: '/api/:db/answer/edit'
      },
      answerDeletePost: {
        path: '/api/:db/answer/delete'
      }
    },
    periodicity: {
      periodicityPageGet: {
        path: '/api/:db/periodicity/page'
      },
      periodicitySavePost: {
        path: '/api/:db/periodicity/save'
      },
      periodicitysGet: {
        path: '/api/:db/periodicity'
      },
      periodicityGet: {
        path: '/api/:db/periodicity/edit'
      },
      periodicityDeletePost: {
        path: '/api/:db/periodicity/delete'
      }
    },
    substance: {
      substancePageGet: {
        path: '/api/:db/substance/page'
      },
      substanceSavePost: {
        path: '/api/:db/substance/save'
      },
      substancesGet: {
        path: '/api/:db/substance'
      },
      substanceGet: {
        path: '/api/:db/substance/edit'
      },
      substanceDeletePost: {
        path: '/api/:db/substance/delete'
      }
    },
    viii: {
      viiiPageGet: {
        path: '/api/:db/viii/page'
      },
      viiiSavePost: {
        path: '/api/:db/viii/save'
      },
      viiisGet: {
        path: '/api/:db/viii'
      },
      viiiGet: {
        path: '/api/:db/viii/edit'
      },
      viiiDeletePost: {
        path: '/api/:db/viii/delete'
      }
    },
    reasonFolder: {
      reasonFolderPageGet: {
        path: '/api/:db/reason-folder/page'
      },
      reasonFolderSavePost: {
        path: '/api/:db/reason-folder/save'
      },
      reasonFoldersGet: {
        path: '/api/:db/reason-folder'
      },
      reasonFolderGet: {
        path: '/api/:db/reason-folder/edit'
      },
      reasonFolderDeletePost: {
        path: '/api/:db/reason-folder/delete'
      }
    },
    reasonVisit: {
      reasonVisitPageGet: {
        path: '/api/:db/reason-visit/page'
      },
      reasonVisitSavePost: {
        path: '/api/:db/reason-visit/save'
      },
      reasonVisitsGet: {
        path: '/api/:db/reason-visit'
      },
      reasonVisitGet: {
        path: '/api/:db/reason-visit/edit'
      },
      reasonVisitDeletePost: {
        path: '/api/:db/reason-visit/delete'
      }
    },
    vaccination: {
      vaccinationPageGet: {
        path: '/api/:db/vaccination/page'
      },
      vaccinationSavePost: {
        path: '/api/:db/vaccination/save'
      },
      vaccinationsGet: {
        path: '/api/:db/vaccination'
      },
      vaccinationGet: {
        path: '/api/:db/vaccination/edit'
      },
      vaccinationDeletePost: {
        path: '/api/:db/vaccination/delete'
      }
    },
    permission: {
      permissionPageGet: {
        path: '/api/permission/page'
      },
      permissionSavePost: {
        path: '/api/permission/save'
      },
      permissionsGet: {
        path: '/api/permission'
      },
      permissionGet: {
        path: '/api/permission/edit'
      },
      permissionDeletePost: {
        path: '/api/permission/delete'
      }
    },
    medicine: {
      medicinePageGet: {
        path: '/api/:db/medicine/page'
      },
      medicineSavePost: {
        path: '/api/:db/medicine/save'
      },
      medicinesGet: {
        path: '/api/:db/medicine'
      },
      medicineGet: {
        path: '/api/:db/medicine/edit'
      },
      medicineDeletePost: {
        path: '/api/:db/medicine/delete'
      }
    },
    medicineAntigen: {
      medicineAntigenPageGet: {
        path: '/api/:db/medicine-antigen/page'
      },
      medicineAntigenSavePost: {
        path: '/api/:db/medicine-antigen/save'
      },
      medicineAntigensGet: {
        path: '/api/:db/medicine-antigen'
      },
      medicineAntigenGet: {
        path: '/api/:db/medicine-antigen/edit'
      },
      medicineAntigenDeletePost: {
        path: '/api/:db/medicine-antigen/delete'
      }
    },
    doseSequence: {
      doseSequencePageGet: {
        path: '/api/:db/dose-sequence/page'
      },
      doseSequenceSavePost: {
        path: '/api/:db/dose-sequence/save'
      },
      doseSequencesGet: {
        path: '/api/:db/dose-sequence'
      },
      doseSequenceGet: {
        path: '/api/:db/dose-sequence/edit'
      },
      doseSequenceDeletePost: {
        path: '/api/:db/dose-sequence/delete'
      }
    },
    reaction: {
      reactionPageGet: {
        path: '/api/:db/reaction/page'
      },
      reactionSavePost: {
        path: '/api/:db/reaction/save'
      },
      reactionsGet: {
        path: '/api/:db/reaction'
      },
      reactionGet: {
        path: '/api/:db/reaction/edit'
      },
      reactionDeletePost: {
        path: '/api/:db/reaction/delete'
      }
    },
    locReaction: {
      locReactionPageGet: {
        path: '/api/:db/loc-reaction/page'
      },
      locReactionSavePost: {
        path: '/api/:db/loc-reaction/save'
      },
      locReactionsGet: {
        path: '/api/:db/loc-reaction'
      },
      locReactionGet: {
        path: '/api/:db/loc-reaction/edit'
      },
      locReactionDeletePost: {
        path: '/api/:db/loc-reaction/delete'
      }
    },
    dpi: {
      dpiPageGet: {
        path: '/api/:db/dpi/page'
      },
      dpiSavePost: {
        path: '/api/:db/dpi/save'
      },
      dpisGet: {
        path: '/api/:db/dpi'
      },
      dpiGet: {
        path: '/api/:db/dpi/edit'
      },
      dpiDeletePost: {
        path: '/api/:db/dpi/delete'
      }
    },
    check: {
      checkPageGet: {
        path: '/api/:db/check/page'
      },
      checkSavePost: {
        path: '/api/:db/check/save'
      },
      checksGet: {
        path: '/api/:db/check'
      },
      checkGet: {
        path: '/api/:db/check/edit'
      },
      checkDeletePost: {
        path: '/api/:db/check/delete'
      },
      workerProtocol: {
        path: '/api/:db/check/risks-protocol'
      }
    },
    program: {
      programSavePost: {
        path: '/api/:db/program'
      },
      programGet: {
        path: '/api/:db/program'
      }
    },
    tools: {
      toolsSavePost: {
        path: '/api/:db/tools'
      },
      toolsGet: {
        path: '/api/:db/tools'
      }
    },
    billing: {
      billingPageGet: {
        path: '/api/:db/billing/page'
      },
      billingsGet: {
        path: '/api/:db/billing'
      },
      billingGet: {
        path: '/api/:db/billing/edit'
      },
      billingSavePost: {
        path: '/api/:db/billing/edit'
      },
      billingToBillingPost: {
        path: '/api/:db/billing/to-billing'
      },
      billingDeletePost: {
        path: '/api/:db/billing/delete'
      },
      billingGetFiltered: {
        path: '/api/:db/billing/filtered'
      }
    },
    rateTable: {
      rateTablePageGet: {
        path: '/api/:db/billing/rate-table/page'
      },
      rateTablesGet: {
        path: '/api/:db/billing/rate-table'
      },
      rateTableGet: {
        path: '/api/:db/billing/rate-table/edit'
      },
      rateTableSavePost: {
        path: '/api/:db/billing/rate-table/edit'
      },
      rateTableDeletePost: {
        path: '/api/:db/billing/rate-table/delete'
      }
    },
    intervention: {
      interventionPageGet: {
        path: '/api/:db/billing/intervention/page'
      },
      interventionsGet: {
        path: '/api/:db/billing/intervention'
      },
      interventionGet: {
        path: '/api/:db/billing/intervention/edit'
      },
      interventionSavePost: {
        path: '/api/:db/billing/intervention/edit'
      },
      interventionDeletePost: {
        path: '/api/:db/billing/intervention/delete'
      }
    },
    billingConfiguration: {
      billingConfigurationGet: {
        path: '/api/:db/billing/configuration'
      },
      billingConfigurationSavePost: {
        path: '/api/:db/billing/configuration/save'
      },
    },
    timetableCompany: {
      timetableCompanyPageGet: {
        path: '/api/:db/timetable/company/page'
      },
      timetableCompaniesGet: {
        path: '/api/:db/timetable/company'
      },
      timetableCompanyGet: {
        path: '/api/:db/timetable/company/edit'
      },
      timetableCompanySavePost: {
        path: '/api/:db/timetable/company/edit'
      },
      timetableCompanyDeletePost: {
        path: '/api/:db/timetable/company/delete'
      }
    },
    timetableCourse: {
      timetableCoursePageGet: {
        path: '/api/:db/timetable/course/page'
      },
      timetableCoursesGet: {
        path: '/api/:db/timetable/course'
      },
      timetableCourseGet: {
        path: '/api/:db/timetable/course/edit'
      },
      timetableCourseSavePost: {
        path: '/api/:db/timetable/course/edit'
      },
      timetableCourseDeletePost: {
        path: '/api/:db/timetable/course/delete'
      }
    },
    timetableInspection: {
      timetableInspectionPageGet: {
        path: '/api/:db/timetable/inspection/page'
      },
      timetableInspectionsGet: {
        path: '/api/:db/timetable/inspection'
      },
      timetableInspectionGet: {
        path: '/api/:db/timetable/inspection/edit'
      },
      timetableInspectionSavePost: {
        path: '/api/:db/timetable/inspection/edit'
      },
      timetableInspectionDeletePost: {
        path: '/api/:db/timetable/inspection/delete'
      }
    },
    timetableSafetyInspection: {
      timetableSafetyInspectionPageGet: {
        path: '/api/:db/timetable/safety-inspection/page'
      },
      timetableSafetyInspectionsGet: {
        path: '/api/:db/timetable/safety-inspection'
      },
      timetableSafetyInspectionGet: {
        path: '/api/:db/timetable/safety-inspection/edit'
      },
      timetableSafetyInspectionSavePost: {
        path: '/api/:db/timetable/safety-inspection/edit'
      },
      timetableSafetyInspectionDeletePost: {
        path: '/api/:db/timetable/safety-inspection/delete'
      }
    },
    timetableWorker: {
      timetableWorkerPageGet: {
        path: '/api/:db/timetable/worker/page'
      },
      timetableWorkersGet: {
        path: '/api/:db/timetable/worker'
      },
      timetableWorkerGet: {
        path: '/api/:db/timetable/worker/edit'
      },
      timetableWorkerSavePost: {
        path: '/api/:db/timetable/worker/edit'
      },
      timetableWorkerDeletePost: {
        path: '/api/:db/timetable/worker/delete'
      }
    },
    timetableAbsence: {
      timetableAbsencePageGet: {
        path: '/api/:db/timetable/absence/page'
      },
      timetableAbsencesGet: {
        path: '/api/:db/timetable/absence'
      },
      timetableAbsenceGet: {
        path: '/api/:db/timetable/absence/edit'
      },
      timetableAbsenceSavePost: {
        path: '/api/:db/timetable/absence/edit'
      },
      timetableAbsenceDeletePost: {
        path: '/api/:db/timetable/absence/delete'
      }
    },
    timetableMonitoring: {
      timetableMonitoringPageGet: {
        path: '/api/:db/timetable/monitoring/page'
      },
      timetableMonitoringsGet: {
        path: '/api/:db/timetable/monitoring'
      },
      timetableMonitoringGet: {
        path: '/api/:db/timetable/monitoring/edit'
      },
      timetableMonitoringSavePost: {
        path: '/api/:db/timetable/monitoring/edit'
      },
      timetableMonitoringDeletePost: {
        path: '/api/:db/timetable/monitoring/delete'
      }
    },
    vaccinationWorker: {
      vaccinationWorkerPageGet: {
        path: '/api/:db/vaccination-worker/page'
      },
      vaccinationWorkersGet: {
        path: '/api/:db/vaccination-worker'
      },
      vaccinationWorkerSavePost: {
        path: '/api/:db/vaccination-worker/save'
      },
      vaccinationWorkerGet: {
        path: '/api/:db/vaccination-worker/edit'
      },
      vaccinationWorkerDeletePost: {
        path: '/api/:db/vaccination-worker/delete'
      }
    },
    'worker/vaccination': {
      'worker/vaccinationPageGet': {
        path: '/api/:db/worker/vaccination/page'
      },
      'worker/vaccinationsGet': {
        path: '/api/:db/worker/vaccination'
      },
      'worker/vaccinationGet': {
        path: '/api/:db/worker/vaccination/edit'
      },
      'worker/vaccinationSavePost': {
        path: '/api/:db/worker/vaccination/save'
      },
      'worker/vaccinationDeletePost': {
        path: '/api/:db/worker/vaccination/delete'
      }
    },
    otherTest: {
      otherTestGet: {
        path: '/api/:db/other-tests/edit'
      },
      otherTestSavePost: {
        path: '/api/:db/other-tests/save'
      },
    },
    legalHeadquarter: {
      legalHeadquarterGet: {
        path: '/api/:db/company/:companyId/legal-headquarters'
      },
      legalHeadquarterSavePost: {
        path: '/api/:db/company/:companyId/legal-headquarters/save'
      },
    },
    administrativeData: {
      administrativeDataPageGet: {
        path: '/api/:db/company/:companyId/administrative-data/page'
      },
      administrativeDatasGet: {
        path: '/api/:db/company/:companyId/administrative-data'
      },
      administrativeDataGet: {
        path: '/api/:db/company/:companyId/administrative-data/edit'
      },
      administrativeDataSavePost: {
        path: '/api/:db/company/:companyId/administrative-data/edit'
      },
      administrativeDataDeletePost: {
        path: '/api/:db/company/:companyId/administrative-data/delete'
      }
    },
    absence: {
      absenceSavePost: {
        path: '/api/:db/absence/save'
      },
      absenceGet: {
        path: '/api/:db/absence'
      },
      absenceDeletePost: {
        path: '/api/:db/delete'
      }
    },
    attachment: {
      attachmentPageGet: {
        path: '/api/:db/attachment/page'
      },
      attachmentsGet: {
        path: '/api/:db/attachment'
      },
      attachmentSavePost: {
        path: '/api/:db/attachment/save'
      },
      attachmentGet: {
        path: '/api/:db/attachment/edit'
      },
      attachmentDeletePost: {
        path: '/api/:db/attachment/delete'
      }
    },
    disability: {
      disabilityPageGet: {
        path: '/api/:db/disability/page'
      },
      disabilitySavePost: {
        path: '/api/:db/disability/save'
      },
      disabilitysGet: {
        path: '/api/:db/disability'
      },
      disabilityGet: {
        path: '/api/:db/disability/edit'
      },
      disabilityDeletePost: {
        path: '/api/:db/disability/delete'
      }
    },
    notification: {
      notificationPageGet: {
        path: '/api/notification/page'
      },
      notificationSavePost: {
        path: '/api/notification/save'
      },
      notificationsGet: {
        path: '/api/notification'
      },
      notificationGet: {
        path: '/api/notification/edit'
      },
      notificationDeletePost: {
        path: '/api/notification/delete'
      }
    },
    recommendation: {
      recommendationPageGet: {
        path: '/api/:db/recommendation/page'
      },
      recommendationSavePost: {
        path: '/api/:db/recommendation/save'
      },
      recommendationsGet: {
        path: '/api/:db/recommendation'
      },
      recommendationGet: {
        path: '/api/:db/recommendation/edit'
      },
      recommendationDeletePost: {
        path: '/api/:db/recommendation/delete'
      }
    },
    customPrint: {
      customPrintPageGet: {
        path: '/api/:db/custom-print/page'
      },
      customPrintSavePost: {
        path: '/api/:db/custom-print/save'
      },
      customPrintsGet: {
        path: '/api/:db/custom-print'
      },
      customPrintGet: {
        path: '/api/:db/custom-print/edit'
      },
      customPrintDeletePost: {
        path: '/api/:db/custom-print/delete'
      }
    },
    driveStatus: {
      driveStatusPageGet: {
        path: '/api/:db/drive/status/page'
      },
      driveStatussGet: {
        path: '/api/:db/drive/status'
      }
    },
    tag: {
      tagPageGet: {
        path: '/api/:db/tag/page'
      },
      tagSavePost: {
        path: '/api/:db/tag/save'
      },
      tagsGet: {
        path: '/api/:db/tag'
      },
      tagGet: {
        path: '/api/:db/tag/edit'
      },
      tagDeletePost: {
        path: '/api/:db/tag/delete'
      }
    },
    course: {
      coursePageGet: {
        path: '/api/:db/course/page'
      },
      courseSavePost: {
        path: '/api/:db/course/save'
      },
      coursesGet: {
        path: '/api/:db/course'
      },
      courseGet: {
        path: '/api/:db/course/edit'
      },
      courseDeletePost: {
        path: '/api/:db/course/delete'
      }
    },
    monitoring: {
      monitoringPageGet: {
        path: '/api/:db/monitoring/page'
      },
      monitoringSavePost: {
        path: '/api/:db/monitoring/save'
      },
      monitoringsGet: {
        path: '/api/:db/monitoring'
      },
      monitoringGet: {
        path: '/api/:db/monitoring/edit'
      },
      monitoringDeletePost: {
        path: '/api/:db/monitoring/delete'
      }
    },
    uptime: {
      checkUptime: {
        path: '/api/info/uptime',
        timeout: 2000
      }
    },
    visitLocation: {
      visitLocationPageGet: {
        path: '/api/:db/visit-location/page'
      },
      visitLocationSavePost: {
        path: '/api/:db/visit-location/save'
      },
      visitLocationsGet: {
        path: '/api/:db/visit-location'
      },
      visitLocationGet: {
        path: '/api/:db/visit-location/edit'
      },
      visitLocationDeletePost: {
        path: '/api/:db/visit-location/delete'
      }
    },
    departmentType: {
      departmentTypePageGet: {
        path: '/api/:db/department-type/page'
      },
      departmentTypeSavePost: {
        path: '/api/:db/department-type/save'
      },
      departmentTypesGet: {
        path: '/api/:db/department-type'
      },
      departmentTypeGet: {
        path: '/api/:db/department-type/edit'
      },
      departmentTypeDeletePost: {
        path: '/api/:db/department-type/delete'
      }
    },
  },
  daysOfWeek: [{
    value: 0,
    label: 'Lunedì',
    shortLabel: 'lun'
  }, {
    value: 1,
    label: 'Martedì',
    shortLabel: 'mar'
  }, {
    value: 2,
    label: 'Mercoledì',
    shortLabel: 'mer'
  }, {
    value: 3,
    label: 'Giovedì',
    shortLabel: 'gio'
  }, {
    value: 4,
    label: 'Venerdì',
    shortLabel: 'ven'
  }, {
    value: 5,
    label: 'Sabato',
    shortLabel: 'sab'
  }, {
    value: 6,
    label: 'Domenica',
    shortLabel: 'dom'
  }],
  messages: {
    errorRequestComponent: {
      message: 'Non è stato possibile caricare completamente la pagina.',
      action: 'Riprova'
    }
  }
}
