import Form from '../containers/Form'
import Print from '../containers/Print'
import ListPage from '../containers/ListPage'
import ListPageAjax from '../containers/ListPageAjax'
import Questionnaire from '../containers/Questionnaire'
import Update from '../containers/Update'
import Dashboard from '../containers/Dashboard'
import Agenda from '../containers/Agenda'

export const indexRoutes = (appConfig) => {
  return [
    {
      paths: [
        appConfig.appPrefix + '/update'
      ],
      component: Update
    },
    {
      paths: [
        appConfig.appPrefix + '/agenda'
      ],
      component: Agenda
    },
    {
      paths: [
        appConfig.appPrefix + '/print'
      ],
      component: Print
    },
    {
      paths: [
        appConfig.appPrefix + '/annex3b/create',
        appConfig.appPrefix + '/annex3b/edit/:id/:page'
      ],
      component: Form,
      props: {
        formType: 'annex3b'
      }
    },    
    {
      paths: [
        appConfig.appPrefix + '/annex3b/finalize/:id'
      ],
      component: Form,
      props: {
        formType: 'annex3bFinalize'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/annex3b/download/:id'
      ],
      component: Form,
      props: {
        formType: 'annex3bDownload'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/import-workers',
      ],
      component: Form,
      props: {
        formType: 'importWorkers'
      }
    },
    {
      paths: [
        '/',
        appConfig.appPrefix,
      ],
      component: Dashboard,
      props: {
        pageType: 'dashboard'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company'
      ],
      component: ListPageAjax,
      props: {
        pageType: 'company',
        pageTypes: 'companie'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/risk/:id'
      ],
      component: ListPage,
      props: {
        pageType: 'company',
        pageTypes: 'companie'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/create',
        appConfig.appPrefix + '/company/clone/:sourceId',
        appConfig.appPrefix + '/company/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'company'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/join/:id'
      ],
      component: Form,
      props: {
        formType: 'joinCompany'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/export/:id'
      ],
      component: Form,
      props: {
        formType: 'exportCompany'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/department',
      ],
      component: ListPage,
      props: {
        pageType: 'department'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/department/create',
        appConfig.appPrefix + '/company/:companyId/department/edit/:id',
        appConfig.appPrefix + '/company/:companyId/department/clone/:sourceId',
      ],
      component: Form,
      props: {
        formType: 'department'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/inspection-report/create',
        appConfig.appPrefix + '/company/:companyId/inspection-report/edit/:id',
        appConfig.appPrefix + '/company/:companyId/inspection-report/clone/:sourceId',
      ],
      component: Form,
      props: {
        formType: 'inspectionReport',
        goToEdit: {
          current: 'inspection-report'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/safety-inspection-report/create',
        appConfig.appPrefix + '/company/:companyId/safety-inspection-report/edit/:id',
        appConfig.appPrefix + '/company/:companyId/safety-inspection-report/clone/:sourceId',
      ],
      component: Form,
      props: {
        formType: 'safetyInspectionReport',
        goToEdit: {
          current: 'safety-inspection-report'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/health-report/create',
        appConfig.appPrefix + '/company/:companyId/health-report/edit/:id',
        appConfig.appPrefix + '/company/:companyId/health-report/clone/:sourceId',
      ],
      component: Form,
      props: {
        formType: 'healthReport',
        goToEdit: {
          current: 'health-report'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/expose-register/create',
        appConfig.appPrefix + '/company/:companyId/expose-register/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'exposeRegister',
        goToEdit: {
          current: 'expose-register'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/rspp-relation',
      ],
      component: ListPage,
      props: {
        pageType: 'rsppRelation'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/rspp-relation/create',
        appConfig.appPrefix + '/company/:companyId/rspp-relation/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'rsppRelation',
        goToEdit: {
          current: 'rspp-relation'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/due-act/create',
        appConfig.appPrefix + '/company/:companyId/due-act/edit/:id',
        appConfig.appPrefix + '/company/:companyId/due-act/clone/:sourceId',
      ],
      component: Form,
      props: {
        formType: 'dueAct',
        goToEdit: {
          current: 'due-act'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:id/agenda',
      ],
      component: Form,
      props: {
        formType: 'agenda',
        goToEdit: {
          current: 'agenda'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/task',
      ],
      component: ListPage,
      props: {
        pageType: 'task'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/task/create',
        appConfig.appPrefix + '/company/:companyId/task/edit/:id',
        appConfig.appPrefix + '/company/:companyId/task/clone/:sourceId',
      ],
      component: Form,
      props: {
        formType: 'task'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker'
      ],
      component: ListPageAjax,
      props: {
        pageType: 'worker'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker'
      ],
      component: ListPageAjax,
      props: {
        pageType: 'companyWorker'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/create',
        appConfig.appPrefix + '/company/:companyId/worker/create',
        appConfig.appPrefix + '/company/worker/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/edit/:id',
        appConfig.appPrefix + '/company/worker/clone/:sourceId',
        appConfig.appPrefix + '/company/:companyId/worker/clone/:sourceId',
        appConfig.appPrefix + '/company/worker/copy/:copyId',
        appConfig.appPrefix + '/company/:companyId/worker/copy/:copyId',
      ],
      component: Form,
      props: {
        formType: 'worker'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/:workerId/worker-print',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/worker-print'
      ],
      component: ListPage,
      props: {
        pageType: 'workerPrint'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/:workerId/questionnaire/create/:type',
        appConfig.appPrefix + '/company/worker/:workerId/questionnaire/edit/:id',
        appConfig.appPrefix + '/company/worker/:workerId/check/:checkId/questionnaire/create/:type',
        appConfig.appPrefix + '/company/worker/:workerId/check/:checkId/questionnaire/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/questionnaire/create/:type',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/questionnaire/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/questionnaire/clone/:sourceId',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:checkId/questionnaire/create/:type',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:checkId/questionnaire/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:checkId/questionnaire/clone/:sourceId'
      ],
      component: Questionnaire,
      props: {
        formType: 'questionnaire',
        goToEdit: {
          current: 'questionnaire',
          append: 'questionnaires',
          appendIfNotParent: 'worker'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/user',
      ],
      component: ListPage,
      props: {
        pageType: 'user'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/admin-user',
      ],
      component: ListPage,
      props: {
        pageType: 'userSetting'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/admin-user/logs/:id',
      ],
      component: ListPage,
      props: {
        pageType: 'userLog'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/prescription-type',
      ],
      component: ListPage,
      props: {
        pageType: 'prescriptionType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/prescription-type/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/prescription-type/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'prescriptionType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/limitation-type',
      ],
      component: ListPage,
      props: {
        pageType: 'limitationType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/limitation-type/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/limitation-type/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'limitationType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/user/profile',
      ],
      component: Form,
      props: {
        formType: 'userProfile'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/admin-user/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/admin-user/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'userSetting'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/user/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/user/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'user'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/role',
      ],
      component: ListPage,
      props: {
        pageType: 'role'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/role/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/role/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'role'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/address-type',
      ],
      component: ListPage,
      props: {
        pageType: 'addressType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/address-type/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/address-type/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'addressType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/worker-title',
      ],
      component: ListPage,
      props: {
        pageType: 'workerTitle'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/worker-title/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/worker-title/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'workerTitle'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/audio-diagnosis',
      ],
      component: ListPage,
      props: {
        pageType: 'audioDiagnosis'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/audio-diagnosis/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/audio-diagnosis/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'audioDiagnosis'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/contact-type',
      ],
      component: ListPage,
      props: {
        pageType: 'contactType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/contact-type/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/contact-type/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'contactType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/tasks-tank',
        appConfig.appPrefix + appConfig.settingPrefix + '/tasks-tank/risk/:id',
      ],
      component: ListPage,
      props: {
        pageType: 'tasksTank'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/tasks-tank/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/tasks-tank/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'tasksTank'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/risk-factor',
      ],
      component: ListPage,
      props: {
        pageType: 'riskFactor'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/risk-factor/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/risk-factor/edit/:id',
        // appConfig.appPrefix + appConfig.settingPrefix + '/risk-factor/risk-preview',
      ],
      component: Form,
      props: {
        formType: 'riskFactor'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/assessment',
      ],
      component: ListPage,
      props: {
        pageType: 'assessment'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/assessment/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/assessment/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'assessment'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/result-type',
      ],
      component: ListPage,
      props: {
        pageType: 'resultType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/result-type/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/result-type/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'resultType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/group3b',
      ],
      component: ListPage,
      props: {
        pageType: 'group3b'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/group3b/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/group3b/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'group3b'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/system/:serviceName',
        appConfig.appPrefix + appConfig.settingPrefix + '/system',
      ],
      component: Form,
      props: {
        formType: 'system'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/question/:serviceName',
      ],
      component: ListPage,
      props: {
        pageType: 'question'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/question/:serviceName/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/question/:serviceName/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'question'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/answer/:serviceName',
      ],
      component: ListPage,
      props: {
        pageType: 'answer'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/answer/:serviceName/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/answer/:serviceName/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'answer'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/periodicity',
      ],
      component: ListPage,
      props: {
        pageType: 'periodicity'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/periodicity/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/periodicity/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'periodicity'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/substance',
      ],
      component: ListPage,
      props: {
        pageType: 'substance'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/substance/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/substance/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'substance'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/viii',
      ],
      component: ListPage,
      props: {
        pageType: 'viii'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/viii/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/viii/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'viii'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/reason-folder',
      ],
      component: ListPage,
      props: {
        pageType: 'reasonFolder'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/reason-folder/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/reason-folder/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'reasonFolder'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/reason-visit',
      ],
      component: ListPage,
      props: {
        pageType: 'reasonVisit'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/reason-visit/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/reason-visit/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'reasonVisit'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/vaccination',
      ],
      component: ListPage,
      props: {
        pageType: 'vaccination'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/vaccination/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/vaccination/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'vaccination'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/permission',
      ],
      component: ListPage,
      props: {
        pageType: 'permission'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/permission/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/permission/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'permission'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/medicine',
      ],
      component: ListPage,
      props: {
        pageType: 'medicine'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/medicine/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/medicine/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'medicine'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/medicine-antigen',
      ],
      component: ListPage,
      props: {
        pageType: 'medicine-antigen'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/medicine-antigen/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/medicine-antigen/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'medicine-antigen'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/dose-sequence',
      ],
      component: ListPage,
      props: {
        pageType: 'doseSequence'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/dose-sequence/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/dose-sequence/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'doseSequence'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/reaction',
      ],
      component: ListPage,
      props: {
        pageType: 'reaction'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/reaction/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/reaction/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'reaction'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/loc-reaction',
      ],
      component: ListPage,
      props: {
        pageType: 'locReaction'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/loc-reaction/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/loc-reaction/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'locReaction'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/dpi',
      ],
      component: ListPage,
      props: {
        pageType: 'dpi'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/dpi/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/dpi/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'dpi'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/:workerId/check/create',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/create',
        appConfig.appPrefix + '/company/worker/:workerId/check/create/:serviceName',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/create/:serviceName',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/create/:serviceName',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/create/:serviceName',
        appConfig.appPrefix + '/company/worker/:workerId/check/edit/:id',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/edit/:id',
        appConfig.appPrefix + '/company/worker/:workerId/check/edit/:id/:serviceName',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/edit/:id/:serviceName',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/edit/:id/:serviceName',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/edit/:id/:serviceName'
      ],
      component: Form,
      props: {
        formType: 'check',
        goToEdit: {
          current: 'check',
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/program/:serviceName',
        appConfig.appPrefix + appConfig.settingPrefix + '/program',
      ],
      component: Form,
      props: {
        formType: 'program'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/tools',
      ],
      component: Form,
      props: {
        formType: 'tools'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/billing',
      ],
      component: ListPageAjax,
      props: {
        pageType: 'billing'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/billing/create',
        appConfig.appPrefix + '/billing/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'billing'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/billing/rate-table',
      ],
      component: ListPage,
      props: {
        pageType: 'rateTable'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/billing/rate-table/create',
        appConfig.appPrefix + '/billing/rate-table/edit/:id',
        appConfig.appPrefix + '/billing/rate-table/clone/:sourceId'
      ],
      component: Form,
      props: {
        formType: 'rateTable'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/billing/intervention',
      ],
      component: ListPage,
      props: {
        pageType: 'intervention'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/billing/intervention/create',
        appConfig.appPrefix + '/billing/intervention/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'intervention'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/billing/configuration',
        appConfig.appPrefix + '/billing/configuration/save',
      ],
      component: Form,
      props: {
        formType: 'billingConfiguration'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/company',
      ],
      component: ListPage,
      props: {
        pageType: 'timetableCompany',
        pageTypes: 'timetableCompanie'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/company/create',
        appConfig.appPrefix + '/timetable/company/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'timetableCompany'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/course',
      ],
      component: ListPage,
      props: {
        pageType: 'timetableCourse'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/course/create',
        appConfig.appPrefix + '/timetable/course/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'timetableCourse'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/inspection',
      ],
      component: ListPage,
      props: {
        pageType: 'timetableInspection'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/inspection/create',
        appConfig.appPrefix + '/timetable/inspection/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'timetableInspection'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/safety-inspection',
      ],
      component: ListPage,
      props: {
        pageType: 'timetableSafetyInspection'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/safety-inspection/create',
        appConfig.appPrefix + '/timetable/safety-inspection/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'timetableSafetyInspection'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/monitoring',
      ],
      component: ListPage,
      props: {
        pageType: 'timetableMonitoring'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/monitoring/create',
        appConfig.appPrefix + '/timetable/monitoring/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'timetableMonitoring'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/worker',
      ],
      component: ListPage,
      props: {
        pageType: 'timetableWorker'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/worker/create',
        appConfig.appPrefix + '/timetable/worker/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'timetableWorker'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/absence',
      ],
      component: ListPage,
      props: {
        pageType: 'timetableAbsence'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/timetable/absence/create',
        appConfig.appPrefix + '/timetable/absence/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'timetableAbsence'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/:workerId/check/:checkId/:serviceName/vaccination-worker',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:checkId/:serviceName/vaccination-worker',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker',
      ],
      component: ListPage,
      props: {
        pageType: 'vaccinationWorker'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/:workerId/check/:checkId/:serviceName/vaccination-worker/create',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:checkId/:serviceName/vaccination-worker/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker/create',
        appConfig.appPrefix + '/company/worker/:workerId/check/:checkId/:serviceName/vaccination-worker/edit/:id',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:checkId/:serviceName/vaccination-worker/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker/edit/:id',
        appConfig.appPrefix + '/company/worker/:workerId/check/:checkId/:serviceName/vaccination-worker/clone/:sourceId',
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker/clone/:sourceId',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:checkId/:serviceName/vaccination-worker/clone/:sourceId',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/:checkId/:serviceName/vaccination-worker/clone/:sourceId'
      ],
      component: Form,
      props: {
        formType: 'vaccinationWorker',
        goToEdit: {
          current: 'vaccination',
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/other-tests',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/other-tests/save',
        appConfig.appPrefix + '/company/worker/:workerId/other-tests',
        appConfig.appPrefix + '/company/worker/:workerId/other-tests/save',
      ],
      component: Form,
      props: {
        formType: 'otherTest',
        goToEdit: {
          current: 'other-tests'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/vaccination'
      ],
      component: ListPage,
      props: {
        pageType: 'worker/vaccination'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/vaccination/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/vaccination/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/vaccination/clone/:sourceId'
      ],
      component: Form,
      props: {
        formType: 'worker/vaccination'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/legal-headquarters',
        appConfig.appPrefix + '/company/:companyId/legal-headquarters/save',
      ],
      component: Form,
      props: {
        formType: 'legalHeadquarter',
        goToEdit: {
          current: 'legal-headquarters'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/administrative-data',
      ],
      component: ListPage,
      props: {
        pageType: 'administrativeData'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/administrative-data/create',
        appConfig.appPrefix + '/company/:companyId/administrative-data/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'administrativeData'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/:workerId/absence',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/absence',
      ],
      component: Form,
      props: {
        formType: 'absence',
        goToEdit: {
          current: 'absence'
        }
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/worker/:workerId/check/:type/:checkId/conclusions/attachment/:serviceName',
        appConfig.appPrefix + '/company/worker/:workerId/attachment/:serviceName',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/:checkId/conclusions/attachment/:serviceName',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/attachment/:serviceName',
        appConfig.appPrefix + '/company/:companyId/attachment/:serviceName',
        appConfig.appPrefix + '/company/:companyId/rspp-relation/:relationId/attachment/:serviceName',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/course/:courseId/attachment/:serviceName',
        appConfig.appPrefix + '/company/worker/:workerId/course/:courseId/attachment/:serviceName',
        appConfig.appPrefix + '/company/:companyId/monitoring/:monitoringId/attachment/:serviceName',
        appConfig.appPrefix + '/company/monitoring/:monitoringId/attachment/:serviceName'
      ],
      component: ListPage,
      props: {
        pageType: 'attachment'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/attachment/:serviceName/create',
        appConfig.appPrefix + '/company/:companyId/attachment/:serviceName/edit/:id',
        appConfig.appPrefix + '/company/:companyId/rspp-relation/:relationId/attachment/:serviceName/create',
        appConfig.appPrefix + '/company/:companyId/rspp-relation/:relationId/attachment/:serviceName/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/attachment/:serviceName/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/attachment/:serviceName/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/:checkId/conclusions/attachment/:serviceName/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/check/:type/:checkId/conclusions/attachment/:serviceName/edit/:id',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/course/:courseId/attachment/:serviceName/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/course/:courseId/attachment/:serviceName/edit/:id',
        appConfig.appPrefix + '/company/:companyId/monitoring/:monitoringId/attachment/:serviceName/create',
        appConfig.appPrefix + '/company/:companyId/monitoring/:monitoringId/attachment/:serviceName/edit/:id'
      ],
      component: Form,
      props: {
        formType: 'attachment'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/disability',
        appConfig.appPrefix + '/company/worker/:workerId/disability',
      ],
      component: ListPage,
      props: {
        pageType: 'disability'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/disability/create',
        appConfig.appPrefix + '/company/worker/:workerId/disability/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/disability/edit/:id',
        appConfig.appPrefix + '/company/worker/:workerId/disability/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'disability'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/notification',
      ],
      component: ListPage,
      props: {
        pageType: 'notification'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/notification/create',
        appConfig.appPrefix + '/notification/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'notification'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/recommendation',
      ],
      component: ListPage,
      props: {
        pageType: 'recommendation'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/recommendation/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/recommendation/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'recommendation'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/custom-print',
      ],
      component: ListPage,
      props: {
        pageType: 'customPrint'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/custom-print/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/custom-print/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'customPrint'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/drive/status',
      ],
      component: ListPageAjax,
      props: {
        pageType: 'driveStatus'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/tag',
      ],
      component: ListPage,
      props: {
        pageType: 'tag'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/tag/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/tag/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'tag'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/course/',
        appConfig.appPrefix + '/company/worker/:workerId/course',
      ],
      component: ListPage,
      props: {
        pageType: 'course'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/course/create',
        appConfig.appPrefix + '/company/worker/:workerId/course/create',
        appConfig.appPrefix + '/company/:companyId/worker/:workerId/course/edit/:id',
        appConfig.appPrefix + '/company/worker/:workerId/course/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'course'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/monitoring/',
        appConfig.appPrefix + '/company/monitoring',
      ],
      component: ListPage,
      props: {
        pageType: 'monitoring'
      }
    },
    {
      paths: [
        appConfig.appPrefix + '/company/:companyId/monitoring/create',
        appConfig.appPrefix + '/company/monitoring/create',
        appConfig.appPrefix + '/company/:companyId/monitoring/edit/:id',
        appConfig.appPrefix + '/company/monitoring/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'monitoring'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/visit-location',
      ],
      component: ListPage,
      props: {
        pageType: 'visitLocation'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/visit-location/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/visit-location/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'visitLocation'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/department-type',
      ],
      component: ListPage,
      props: {
        pageType: 'departmentType'
      }
    },
    {
      paths: [
        appConfig.appPrefix + appConfig.settingPrefix + '/department-type/create',
        appConfig.appPrefix + appConfig.settingPrefix + '/department-type/edit/:id',
      ],
      component: Form,
      props: {
        formType: 'departmentType'
      }
    },
  ]
}
