const themeConfig = {
  typography: {
    useNextVariants: true,
    htmlFontSize: 12,
    fontSize: 10, 
    h1: {
      fontSize: 20 
    },
    h2: {
      fontSize: 16, 
    },
    h3: {
      fontSize: 14, 
    },
    h4: {
      fontSize: 12, 
    },
    h5: {
      fontSize: 10, 
    },
    h6: {
      fontSize: 8, 
    },
    subtitle1: {
      fontSize: 12, 
    },
    subtitle2: {
      fontSize: 10, 
    },
    body1: {
      fontSize: 12, 
    },
    body2: {
      fontSize: 10, 
    },
    button: {
      fontSize: 10,
      textTransform: 'uppercase',
    },
    caption: {
      fontSize: 8, 
    },
    overline: {
      fontSize: 8, 
      textTransform: 'uppercase',
    },
    srOnly: {
      fontSize: 10,
    },
    inherit: {
      fontSize: 10,
    },
    display4: {
      fontSize: 10,
    },
    display3: {
      fontSize: 10,
    },
    display2: {
      fontSize: 10,
    },
    display1: {
      fontSize: 10,
    },
    headline: {
      fontSize: 10,
    },
    title: {
      fontSize: 10,
    },
    subheading: {
      fontSize: 10,
    }
  },
  appVars: {
    navBarHeight: 64,
    footerHeight: 50,
    breadcrumbsHeight: 50,
    sidebarWidth: {
      main: 48,
      open: 200
    },
    searchBoxHeight: 60,
  },
  palette: {
    light: {
      type: 'light',
      background: {
        default: '#fff',
        defaultOdd: '#fafafa',
        title: '#F0F0F7',
        muiPaper: '#fff',
        breadcrumb: '#F0F0F7',
        containedButton: '#e0e0e0',
        containedButtonHover: '#d5d5d5',
        readOnly: '#fafafa',
        disabled: '#fafafa',
        colored: '#e6f3ff',
        sidebarRoot: '#0069c0',
        listElementExpanded: '#004c8c',
        menuItemActive: '#004c8c',
        menuItemActiveAfter: '#008bff',
        userIconHover: '#fafafa',
        tabs: '#fff',
        chip: '#e6e6e6',
        formSpecial: '#D3DAFF !important',
        formSpecialHeader: '#eeeeee !important',
        coloredRow: '#FFFFCC',
        controlSelect: '#fff',
        formfields: '#fff',
        preview: 'rgb(240, 240, 247)',
        tableHead: '#fff',
        coloredRowTimetable: '#ffc947',
        steps: '#cfd8dc',
        step: '#b0bec5',
        stepBefore: '#fff',
        stepAfter: '#b0bec5',
        stepActive: '#607d8b',
        tooltip: '#76767a',
        activeTabs: '#004c8c',
        activeTabsHover: 'rgba(0, 76, 140, 0.8)',
        navButtonContainer: '#004c8c',
        loading: 'rgba(255, 255, 255, .95)',
        progressBarContainer: 'rgb(232, 244, 253)'
      },
      text: {
        default: '#000',
        primary: '#43425D', 
        disabled: '#303030',
        focused: '#008bff !important',
        hover: '#43425D',
        required: '#ff0000',
        sidebar: '#fff',
        notClickable: '#d5dce0',
        white: '#fff',
        black: '#000',
        textReverse: '#000'
      },
      primary: {
        main: '#0069c0',
        light: '#008bff',
        dark: '#004c8c'
      },
      error: {
        main: '#f44336',
        light: '#ff7961',
        dark: '#ba000d',
        300: '#611a15',
      },
      warning: {
        main: '#ff9800',
        light: '#ffc947',
        dark: '#c66900'
      },
      success: {
        main: '#4caf50',
        light: '#80e27e',
        dark: '#087f23',
        50: '#edf7ed',
        100: '#38bf60',
        200: '#3f9258',
        300: '#1e4620'
      },
      info: {
        main: '#2196f3',
        light: '#6ec6ff',
        dark: '#0069c0',
        badge: '#3B86FF',
        50: '#e6f3ff',
        300: '#0d3c61'
      },
      placeholder: {
        main: '#F1F1F3',
        dark: '#a1a0ae',
      },
      textColor: color => {
        const hexToRgb = hex => {
          let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  
          return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          } : null
        }
  
        color = hexToRgb(color)
  
        const brightness = Math.sqrt((color.r * color.r * .241) + (color.g * color.g * .691) + (color.b * color.b * .068))
  
        return brightness < 130 ? '#fff' : '#000'
      },
      configDrawer: {
        card: '#ffffff8c',
        cardBorder: '1px solid rgb(134 134 134 / 14%)',
        cardBoxShadow: '0px 3px 6px 0px rgba(140, 149, 159, 0.15)'
      },
      common: {
        icon: 'rgba(0, 0, 0, 0.54)',
        scollbarTrack: '#F0F0F7',
        scollbarThumb: '#989898',
        loading: 'rgba(255, 255, 255, .5)'
      },
      border: {
        primary: '#008bff !important',
        secondary: 'rgba(0, 0, 0, 0.23) !important',
        calendar: '#e0e0e0',
        table: '#ccc'
      },
      select: {
        text: 'rgba(0, 0, 0, 0.54)',
        inputControlBorder: '#43425D',
        inputControlBorderHover: '#c4c4c4',
        inputControlBackground: 'rgb(31, 34, 35)',
        inputControlMenuListBackground: '#fff',
        inputControlPlaceholderText: '#808080',
        inputControlElementHover: 'rgb(222, 235, 255)',
        inputControlElementSelected: '#e6e6e6',
        multiValueListElementBackground: '#e6e6e6'
      },
      notefield: {
        editorToolbar: '#e6e6e6',
        wrappersBackground: '#ffffff',
        optionHover: '#999999',
        optionActive: '#999999',
        dropdownOptionActive: '#999999',
        btn: '#00000085',
        btnHover: '#00000069',
        btnDisabled: '#0000001f',
        dropdownBackground: '#ffffff',
        dropdownHighlighted: '#999999',
        dropdownTextSelected: '#000',
        linkModalInput: '#ffffff0d',
        border: '1px solid #F1F1F1 !important',
        borderEmojiModal: '1px solid #e4e4e4 !important',
        textWhite: '#fff',
        textBlack: '#000',
        textReverse: '#000'
      },
      chatbot: {
        chatbotButtonShadow: '0px 0px 5px 0px grey',
        background: 'linear-gradient(120deg, rgba(0,76,140,1) 0%, rgb(38 136 214) 70%, rgb(60 215 195) 150%)',
        modalHeaderBackground: 'linear-gradient(120deg, rgba(0,76,140,1) 0%, rgb(38 136 214) 70%, rgb(60 215 195) 120%)',
        chatbotMessageBubbleBackground: '#eae8e8',
        chatbotMessageBubbleText: '#000',
        messageTimestampBot: '#616161',
        messageTimestampUser: '#ffffff',
        disclaimerText: '#616161',
        privacyLink: '#000000',
        modalPowered: '#000000',
        medialisLink: '#0990ff',
        medialisLinkHover: 'rgba(9, 144, 255, 0.8)',
        modalFrequentlyAskedButtonText: '#0069c0',
        modalFrequentlyAskedButtonBackgroundBefore: 'linear-gradient(120deg, rgb(34 122 191) 70%, rgb(60 215 195) 140%)',
        modalFrequentlyAskedButtonHover: 'linear-gradient(120deg, rgb(34 122 191) 70%, rgb(60 215 195) 140%)',
        modalFrequentlyAskedButtonBackground: 'white',
        sourceLink: '#0066cc'
      },
      calendar: {
        containerBackground: '#fff',
        wrapperBackground: '#fff',
        monthYearWrapperBackground: '#fff', 
      },
      chartGroupWidgets: {
        borderRight: '1px solid #e0e0e0',
        columnTypeLegendBorderColor: '#CCC'
      },
      agendaWidget: {
        dayCalendarHour: '#fff'
      },
      progressField: {
        bar: 'rgba(0, 0, 0, 0.08)'        
      },
      shadow: {
        counter: 'rgba(149, 157, 165, 0.2) 0px 8px 24px !important',
        tabsActions: '0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)'
      },
      audiometricChart: {
        xAxisBottomBorder: 'rgb(230, 230, 230)',
        gridLines: 'rgb(230, 230, 230)',
        rightEar: 'rgb(223, 36, 36)', 
        leftEar: 'rgb(59, 59, 235)'
      },
      buttonStyles: {
        default: {
          backgroundColor: '#428bca',
          color: '#fff'
        },
        printPdf: {
          backgroundColor: '#428bca',
          color: '#fff'
        },
        printCsv: {
          backgroundColor: '#2e7d32',
          color: '#fff'
        },
        printXml: {
          backgroundColor: '#607d8b',
          color: '#fff'
        },
        printForDrive: {
          backgroundColor: '#877484',
          color: '#fff'
        },
        printDm8899: {
          backgroundColor: '#822433',
          color: '#fff'
        },
        printGroup: {
          backgroundColor: '#428bca',
          color: '#fff'
        },
        refresh: {
          backgroundColor: '#e0e0e0',
          color: '#000'
        }
      }
    },
    dark: {
      type: 'dark',
      background: {
        default: '#141A21',
        defaultOdd: '#262e38',
        title: '#29333e',
        muiPaper: '#1C252E',
        breadcrumb: '#1C252E',
        containedButton: '#243342',
        containedButtonHover: '#365370',
        readOnly: '#262e38',
        disabled: '#262e38',
        colored: '#1a2327',
        sidebarRoot: '#1c262f',
        listElementExpanded: '#182128',
        menuItemActive: '#1b2d43',
        menuItemActiveAfter: '#3B82F6',
        userIconHover: '#1a222c',
        tabs: '#262e38',
        chip: '#2c3741',
        formSpecial: 'rgb(39, 43, 44) !important',
        formSpecialHeader: '#262e38 !important',
        coloredRow: '#3d3921',
        controlSelect: 'none',
        formfields: 'none',
        preview: '#141A21',
        tableHead: '#29333e',
        coloredRowTimetable: '#3d3921',
        steps: '#141b23',
        step: '#182b42',
        stepBefore: '#465e79',
        stepAfter: '#182b42',
        stepActive: '#204167',
        tooltip: '#365370',
        activeTabs: '#365370',
        activeTabsHover: 'rgba(54, 83, 112, 0.8)',
        navButtonContainer: '#1b2d43',
        loading: '#1c252ef7',
        progressBarContainer: '#262e38'
      },
      text: {
        default: '#fff',
        primary: '#fff',
        disabled: '#b4b4b4',
        focused: '#fff !important',
        hover: 'rgb(237, 228, 216)',
        required: '#ff0000',
        sidebar: '#fff',
        notClickable: '#595959',
        white: '#fff',
        black: '#000',
        textReverse: '#fff'
      },
      primary: {
        main: '#0069c0',
        light: '#008bff',
        dark: '#004c8c'
      },
      error: {
        main: '#f44336',
        light: '#e38b7b',
        dark: '#ba000d',
        300: '#611a15',
      },
      warning: {
        main: '#ff9800',
        light: '#ffc947',
        dark: '#c66900'
      },
      success: {
        main: '#4caf50',
        light: '#80e27e',
        dark: '#087f23',
        50: '#30483e',
        100: '#38bf60',
        200: '#3f9258',
        300: '#aad6b1'
      },
      info: {
        main: '#2196f3',
        light: '#6ec6ff',
        dark: '#0069c0',
        badge: '#3B86FF',
        50: '#083d68',
        300: '#c0e1ff'
      },
      placeholder: {
        main: '#9198a140',
        dark: '#a1a0ae',
      },
      textColor: color => {
        const hexToRgb = hex => {
          let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  
          return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          } : null
        }
  
        color = hexToRgb(color)
  
        const brightness = Math.sqrt((color.r * color.r * .241) + (color.g * color.g * .691) + (color.b * color.b * .068))
  
        return brightness < 130 ? '#fff' : '#000'
      },
      configDrawer: {
        card: '#6262620f',
        cardBorder: '1px solid rgb(103 103 103 / 20%)',
        cardBoxShadow: 'none'
      },
      common: {
        icon: 'rgb(255 255 255 / 44%)',
        scollbarTrack: '#121212',
        scollbarThumb: '#9198a1',
        loading: 'rgba(44, 55, 65, .5)'
      },
      border: {
        primary: 'rgba(255, 255, 255, 0.23) !important',
        secondary: 'rgba(255, 255, 255, 0.23) !important',
        calendar: '#43425D',
        table: '#e0e0e02e'
      },
      select: {
        text: '#fff',
        inputControlBorder: '#FFFFFF',
        inputControlBorderHover: 'rgba(255, 255, 255, 0.23)',
        inputControlBackground: 'rgb(31, 34, 35)',
        inputControlMenuListBackground: '#2c3741',
        inputControlPlaceholderText: '#a3a3a3',
        inputControlElementHover: '#1d2b3d',
        inputControlElementSelected: '#2c3741',
        multiValueListElementBackground: '#2c3741'
      },
      notefield: {
        editorToolbar: '#131a21',
        wrappersBackground: '#243d56',
        optionHover: '#6a89a8',
        optionActive: '#6a89a8',
        dropdownOptionActive: '#1b3855',
        btn: 'rgb(52, 73, 94)',
        btnHover: '#34495ea8',
        btnDisabled: '#34495e87',
        dropdownBackground: '#34495e',
        dropdownHighlighted: 'rgba(42, 62, 82, 0.8)',
        dropdownTextSelected: '#000',
        linkModalInput: '#ffffff0d',
        border: 'none',
        borderEmojiModal: 'none',
        textWhite: '#fff',
        textBlack: '#000',
        textReverse: '#fff'
      },
      chatbot: {
        chatbotButtonShadow: 'none',
        background: 'linear-gradient(120deg, rgba(0,76,140,1) 0%, rgb(38 136 214) 70%, rgb(60 215 195) 150%)',
        modalHeaderBackground: 'linear-gradient(120deg, rgba(0,76,140,1) 0%, rgb(38 136 214) 70%, rgb(60 215 195) 120%)',
        chatbotMessageBubbleBackground: '#445a6f',
        chatbotMessageBubbleText: '#fff',
        messageTimestampBot: '#ffffff94',
        messageTimestampUser: '#ffffff',
        disclaimerText: '#828282',
        privacyLink: '#ffffff99',
        modalPowered: '#ffffff99',
        medialisLink: '#0990ff',
        medialisLinkHover: 'rgba(9, 144, 255, 0.8)',
        modalFrequentlyAskedButtonText: '#fff',
        modalFrequentlyAskedButtonBackgroundBefore: 'none',
        modalFrequentlyAskedButtonHover: 'linear-gradient(120deg, rgb(34 122 191) 70%, rgb(60 215 195) 140%)',
        modalFrequentlyAskedButtonBackground: '#293e4d',
        sourceLink: '#58acff'
      },
      calendar: {
        containerBackground: '#2c3741',
        wrapperBackground: '#2c3741',
        monthYearWrapperBackground: '#2c3741', 
      },
      chartGroupWidgets: {
        borderRight: '1px solid #e0e0e02e',
        columnTypeLegendBorderColor: '#e0e0e02e'
      },
      agendaWidget: {
        dayCalendarHour: '#262529'
      },
      progressField: {
        bar: '#2c3741'
      },
      shadow: {
        counter: 'none',
        tabsActions: 'inset 0 0 0.5px 1px hsla(0, 0%, 100%, 0.075), 0 0 0 1px hsla(0, 0%, 0%, 0.05),0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),0 3.5px 6px hsla(0, 0%, 0%, 0.09);'
      },
      audiometricChart: {
        xAxisBottomBorder: 'rgb(230, 230, 230)',
        gridLines: 'rgba(230, 230, 230, 0.22)',
        rightEar: 'rgb(255, 68, 68)', 
        leftEar: 'rgb(75, 75, 255)'
      },
      buttonStyles: {
        default: {
          backgroundColor: '#0069c0',
          color: '#fff'
        },
        printPdf: {
          backgroundColor: '#0069c0',
          color: '#fff'
        },
        printCsv: {
          backgroundColor: '#276c2b',
          color: '#fff'
        },
        printXml: {
          backgroundColor: '#49616d',
          color: '#fff'
        },
        printForDrive: {
          backgroundColor: '#5d4b5a',
          color: '#fff'
        },
        printDm8899: {
          backgroundColor: '#822433',
          color: '#fff'
        },
        printGroup: {
          backgroundColor: '#0069c0',
          color: '#fff'
        },
        refresh: {
          backgroundColor: '#33404e',
          color: '#fff'
        },
        reset: {
          backgroundColor: '#f0ad4e',
          color: '#fff'
        }
      },
    }
  }
}

const scaleTypography = (typography, scale) => {
  const scaledTypography = {}

  for (const key in typography) {
    if (typography[key].fontSize) {
      scaledTypography[key] = {
        ...typography[key],
        fontSize: typography[key].fontSize * scale
      }
    } else {
      scaledTypography[key] = typography[key]
    }
  }

  return scaledTypography
}

const getThemeConfig = (darkMode, fontSizeScale) => ({
  ...themeConfig,
  palette: darkMode ? themeConfig.palette.dark : themeConfig.palette.light,
  typography: scaleTypography(themeConfig.typography, fontSizeScale),
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html': {
          colorScheme: darkMode ? 'dark !important' : 'light !important',
          scrollbarColor: darkMode ? `${themeConfig.palette.dark.select.inputControlBorder} transparent` : 'auto',
          '*::-webkit-scrollbar': {
            width: '8px',
            height: '8px'
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: darkMode ? themeConfig.palette.dark.select.inputControlBorder : 'rgba(0, 0, 0, 0.2)',
            borderRadius: '4px'
          },
          '*::-webkit-scrollbar-track': {
            backgroundColor: 'transparent'
          }
        }
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: darkMode ? themeConfig.palette.dark.background.muiPaper : themeConfig.palette.light.background.muiPaper,
        color: darkMode ? themeConfig.palette.dark.text.default : themeConfig.palette.light.text.default
      },
      elevation0: {
        boxShadow: darkMode ? 'none' : 'rgba(149, 157, 165, 0.2) 0px 8px 24px !important'
      }
    },
    MuiTypography: {
      caption: {
        fontSize: `clamp(13px, ${12 * fontSizeScale}px, 16px)`,
      },
      colorTextPrimary: {
        fontSize: `clamp(50px, ${12 * fontSizeScale}px, 55px)`,
      },
      h5: {
        fontSize: `clamp(16px, ${12 * fontSizeScale}px, 18px)`,
      },
      body2: {
        fontSize: `clamp(16px, ${12 * fontSizeScale}px, 18px)`,
      },
      subtitle1: {
        fontSize: `clamp(13px, ${12 * fontSizeScale}px, 16px)`,
      },
      h6: {
        fontSize: `clamp(13px, ${12 * fontSizeScale}px, 16px)`,
      }
    },
    MuiButton: {
      root: {
        fontSize: `clamp(10px, ${12 * fontSizeScale}px, 16px)`,
        color: darkMode ? themeConfig.palette.dark.text.default : themeConfig.palette.light.text.default
      },
      contained: {
        color: darkMode ? themeConfig.palette.dark.text.default : themeConfig.palette.light.text.default,
        backgroundColor: darkMode ? themeConfig.palette.dark.background.containedButton : themeConfig.palette.light.background.containedButton,
        '&:hover': {
          backgroundColor: darkMode ? themeConfig.palette.dark.background.containedButtonHover : themeConfig.palette.light.background.containedButtonHover
        }
      },
    },
    MuiInputBase: {
      input: {
        color: darkMode ? themeConfig.palette.dark.text.default : themeConfig.palette.light.text.default
      }
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderColor: darkMode ? themeConfig.palette.dark.select.inputControlBorder : themeConfig.palette.light.border.primary
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: `clamp(12px, ${12 * fontSizeScale}px, 16px) !important`,
        color: darkMode ? themeConfig.palette.dark.text.default : themeConfig.palette.light.text.default, 
        '&$focused': {
          color: darkMode ? themeConfig.palette.dark.text.focused : themeConfig.palette.light.text.focused,
          backgroundColor: darkMode ? themeConfig.palette.dark.background.muiPaper : themeConfig.palette.light.background.muiPaper,
        }        
      },       
    },
    MuiTableCell: {
      body: {
        color: darkMode ? themeConfig.palette.dark.text.primary : themeConfig.palette.light.text.primary
      }
    },
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: darkMode ? themeConfig.palette.dark.text.hover : themeConfig.palette.light.text.hover
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkMode ? themeConfig.palette.dark.background.tooltip : themeConfig.palette.light.background.tooltip,
        fontWeight: 500,
        fontSize: themeConfig.typography.subtitle1
      }
    },
    ModalActions: {
      header: {
        fontSize: '20px'
      }
    }
  }
})

const theme = {
  themeConfig,
  getThemeConfig
}
  
export default theme